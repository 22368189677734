<template>
  <div class="content">
    <v-row justify="center">
      <v-dialog v-model="form_arancel" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <div class="col-md-12" align="right" style="background: #eee;">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              class="mb-4"
              v-on="on"
              @click="form_arancel = true"
            >
              Nuevo Arancel
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >{{ editarArancel ? "Editar" : "Nuevo" }} Arancel</span
            >
            <v-spacer></v-spacer>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="form_arancel = false"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-card-title>

          <div v-if="procensando_datos">
            <loader
              object="#4caf50"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="40"
              disableScrolling="false"
              name="box"
            ></loader>
          </div>

          <!--formulario-->
          <v-container>
            <v-form @submit.prevent="submit" ref="form" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="Importe"
                        :counter="30"
                        label="Coeficiente"
                        hint="Ejemplo: 0.1234"
                        :rules="[(v) => !!v || 'Un Importe es requerido']"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="Importeminimo"
                        label="Importe Mínimo"
                        prefix="$"
                        type="number"
                        min="0"
                        step="1"
                        :rules="[
                          (v) => !!v || 'Un Importe mínimo es requerido',
                        ]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="Importemaximo"
                        label="Importe Máximo"
                        prefix="$"
                        type="number"
                        min="0"
                        step="1"
                        :rules="[
                          (v) => !!v || 'Un Importe máximo es requerido',
                        ]"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      style="display: flex; justify-content: center"
                    >
                      <v-radio-group
                        row
                        v-model="Tipocalculo"
                        :rules="[
                          (v) => !!v || 'Un Tipo de Calculo es requerido',
                        ]"
                      >
                        <v-radio value="PORCENTAJE">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">PORCENTAJE</strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="FIJO">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">FIJO</strong>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="RubrosId"
                        :items="rubros"
                        item-text="rubro"
                        item-value="id"
                        label="Rubro"
                        :rules="[(v) => !!v || 'Un Rubro es requerido']"
                        required
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="ChannelsId"
                        :items="canales"
                        item-text="Nombre"
                        item-value="Id"
                        label="Canal de Pago"
                        :rules="[(v) => !!v || 'Un Canal de Pago es requerido']"
                        required
                        @change="
                          () =>
                            ChannelsId === 1
                              ? (showRadios = true)
                              : (showRadios = false)
                        "
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <!-- RADIO SELECT UNA O VARIAS CUOTAS -->
                  <v-row>
                    <v-col cols="12" v-if="showRadios">
                      <v-radio-group row v-model="Pagocuota">
                        <template v-slot:label>
                          <div>
                            Seleccione si la comisión del canal de pago crédito
                            es para una o varias cuotas.
                          </div>
                        </template>
                        <v-radio value="false">
                          <template v-slot:label>
                            <strong class="primary--text">Una</strong> cuota
                          </template>
                        </v-radio>
                        <v-radio value="true">
                          <template v-slot:label>
                            <strong class="primary--text">Varias</strong>
                            cuotas
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="mediopago_id"
                        :items="medios"
                        item-text="mediopago"
                        item-value="ID"
                        label="Medios de pago (opcional)"
                        required
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <!-- valor del text -->
                          <v-text-field
                            prepend-icon="mdi-calendar"
                            label="Vigencia desde"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            persistent-hint
                            :value="
                              Fechadesde.length > 0
                                ? formatDate(Fechadesde)
                                : ''
                            "
                            :rules="[
                              (v) =>
                                !!v || 'Una Fecha de Vigencia es requerido',
                            ]"
                            required
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="Fechadesde"
                          locale="es-ar"
                          no-title
                          dark
                          scrollable
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="form_arancel = false">
              Cerrar
            </v-btn>
            <v-btn color="blue darken-1" text @click="guardar_arancel()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Aranceles</h4>
              <p class="card-category"></p>
            </div>
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>

            <section v-else>
              <!-- DATOS DE LA TABLA -->
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :search="search"
                :headers="headers"
                :items="aranceles"
                :loading="cargando"
                :items-per-page="rowsPerPage"
                hide-default-footer
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-0 trow"
              >
                <template v-slot:[`item.RubrosId`]="{ item }">
                  {{ nombreRubro(item) }}
                </template>
                <template v-slot:[`item.ChannelsId`]="{ item }">
                  {{ nombreCanal(item) }}
                </template>
                <template v-slot:[`item.Importe`]="{ item }">
                  {{
                    item.Tipocalculo == "PORCENTAJE"
                      ? decimales(item) + " %"
                      : "$ " + decimales(item)
                  }}
                </template>
                <template v-slot:[`item.Fechadesde`]="{ item }">
                  {{ formatDate(item.Fechadesde) }}
                </template>
                <template v-slot:[`item.Mediopagoid`]="{ item }">
                  {{ nombreMedio(item) }}
                </template>

                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon color="orange" @click="editItem(item)">
                    create
                  </v-icon>
                </template>

                <!-- FOOTER DE LA TABLA -->
                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { ChannelsArancel } from "../../services/channelsarancel.js";
import { Medios } from "../../services/mediopagos";
import { Channels } from "../../services/channels";
import { Rubros } from "../../services/rubros.js";
import moment from "moment";
import formatDate from "../../utils/formatDate";

export default {
  name: "Aranceles",
  data: () => ({
    aranceles: [],
    medios: [],
    canales: [],
    rubros: [],
    cargarChannel: true,
    procensando_datos: false,
    cargando: false,
    dialog: false,
    errored: false,
    form_arancel: false,
    editarArancel: false,

    menu1: false, // para el datepicker
    showRadios: false, // para seleccionar si se paga en una o varias cuotas

    mediopago_id: "",

    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },

    // Filter models.
    search: "",
    headers: [
      { text: "Rubro", value: "RubrosId" },
      { text: "Canal", value: "ChannelsId" },
      { text: "Tipo", value: "Tipocalculo" },
      { text: "Valor", value: "Importe" },
      { text: "Vigencia Desde", value: "Fechadesde" },
      { text: "Medio", value: "Mediopagoid" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,

    // datos del arancel
    Id: "",
    RubrosId: "",
    Importe: "",
    ChannelsId: "",
    Fechadesde: "",
    Tipocalculo: "",
    Importeminimo: "",
    Importemaximo: "",
    Pagocuota: "false",

    formatDate,
  }),

  created() {
    this.getMediosPago();
    this.getCanalesPago();
    this.getRubros();
    this.getdatos();
  },

  watch: {
    form_arancel(formularioAbierto) {
      if (!formularioAbierto) {
        this.$refs.form.reset();
        this.Importeminimo = "";
        this.Importemaximo = "";
      }
    },
  },

  methods: {
    decimales(item) {
      let monto = "";
      item.Tipocalculo === "PORCENTAJE"
        ? (monto = (item.Importe * 100).toFixed(1))
        : (monto = item.Importe.toFixed(2));
      return monto;
    },
    getdatos: function() {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
        CargarChannel: this.cargarChannel,
        CargarAllMedioPago: true,
      };
      this.cargando = true;
      ChannelsArancel.getChannelsAranceles(params)
        .then((response) => {
          if (response.data.data == null) {
            this.aranceles = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.aranceles = response.data.data;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
            this.cargando = false;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },

    // paginar
    onPageChange() {
      this.getdatos();
    },
    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getdatos();
    },
    getMediosPago() {
      Medios.getMediosPago()
        .then(({ data }) => {
          this.medios = data.data;
          this.medios.unshift({ ID: 0, mediopago: "-" });
        })
        .catch((error) => {
          // console.error(error)
        });
    },
    getCanalesPago: function() {
      const params = {
        Number: 1,
        Size: 20,
      };
      Channels.getChannels(params)
        .then((response) => {
          if (response.data.data == null) {
            this.canales = [];
          } else {
            this.canales = response.data.data;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally();
    },
    getRubros: function() {
      const params = {
        Number: 1,
        Size: 20,
      };
      Rubros.getRubros(params)
        .then((response) => {
          if (response.data.data == null) {
            this.rubros = [];
          } else {
            this.rubros = response.data.data;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally();
    },
    nombreMedio(arancel) {
      let medio = this.medios.find((medio) => medio.ID == arancel.Mediopagoid);
      return medio != null ? medio.mediopago : "";
    },
    nombreCanal(arancel) {
      let canal = this.canales.find((canal) => canal.Id == arancel.ChannelsId);
      return canal.Nombre;
    },
    nombreRubro(arancel) {
      let rubro = this.rubros.find((rubro) => rubro.id == arancel.RubrosId);
      return rubro.rubro;
    },

    editItem(item) {
      this.consultar_arancel(item);
      this.form_arancel = true;
      this.editarArancel = true;
    },

    consultar_arancel: function(item) {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
        Id: item.Id,
      };
      this.cargando = true;
      ChannelsArancel.getChannelArancel(params)
        .then(({ data }) => {
          this.Id = data.Id;
          this.RubrosId = data.RubrosId;
          this.Importe = data.Importe;
          this.ChannelsId = data.ChannelsId;
          this.Fechadesde = data.Fechadesde;
          this.Tipocalculo = data.Tipocalculo;
          this.Importeminimo = String(data.Importeminimo);
          this.Importemaximo = String(data.Importemaximo);
          this.mediopago = data.Mediopagoid;
          this.Pagocuota = String(data.Pagocuota);
          this.showRadios = this.ChannelsId == 1 ? true : false;
        })
        .catch((error) => {
          // console.log(error);
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    async actualizar(datosArancel) {
      await ChannelsArancel.actualizar_channelArancel(datosArancel)
        .then(({ data }) => {
          if (data.modificado) {
            this.$toastr.s("El arancel se actualizo correctamente");
            // actualziar tabla de clientes
            this.getdatos();
          } else {
            this.error = true;
            this.$toastr.e(
              "Ocurrió un error al tratar de actualizar el arancel."
            );
          }
        })
        .catch((error) => {
          const data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => {
          this.procensando_datos = false;
          this.form_arancel = false;
        });
    },

    async guardar_arancel() {
      // valor por defecto a importe minimo y maximo
      if (this.Importeminimo.length === 0) this.Importeminimo = "0";
      if (this.Importemaximo.length === 0) this.Importemaximo = "0";

      //comprobando datos del formulario
      if (this.$refs.form.validate()) {
        let datosArancel = {
          RubrosId: parseInt(this.RubrosId),
          ChannelsId: parseInt(this.ChannelsId),
          Importe: parseFloat(this.Importe),
          Fechadesde: moment(this.Fechadesde).toISOString(),
          Tipocalculo: this.Tipocalculo,
          Importeminimo: parseFloat(this.Importeminimo),
          Importemaximo: parseFloat(this.Importemaximo),
          Mediopagoid:
            this.mediopago_id == "" ? 0 : parseInt(this.mediopago_id),
          Pagocuota: this.Pagocuota === "true",
        };

        this.procensando_datos = true;
        if (!this.editarArancel) {
          await ChannelsArancel.nuevo_channelArancel(datosArancel)
            .then(() => {
              this.getdatos();
              this.form_arancel = false;
              this.$toastr.s("El Arancel se creo correctamente");
            })
            .catch((error) => {
              const data = error.response.data;
              this.error = true;
              this.$toastr.e(data.message);
            })
            .finally(() => (this.procensando_datos = false));
        } else {
          datosArancel = { ...datosArancel, Id: this.Id };
          this.actualizar(datosArancel);
        }
      }
    },
  },
};
</script>
<style scoped>
a {
  color: #f7f7f7;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
</style>
